<template>
  <div class="newsList">
    <van-pull-refresh v-model="isLoading"
                      @refresh="onRefresh">
      <div style="padding-bottom: 55px;">
        <van-list :error.sync="error"
                  error-text="请求失败，点击重新加载"
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad"
                  ref="scrollNews">
          <div class="info-list" v-for="(item,index) in infoList" :key="index" @click="newsClick(item.id)">
            <div class="flex" v-if="item.imageurls.length < 3 || item.imageurls.length == 0">
              <div class="left flex">
                <div class="title">{{item.title | ellipsisTop}}</div>
                <div class="desc flex">
                  <div>{{item.channelName}}</div>
                  <div>
                    <span>{{item.source}}</span>
                    <span>{{item.pubDate | dateFilter}}</span>
                  </div>
                </div>
              </div>
              <div class="right" v-if="item.imageurls.length < 3 && item.imageurls.length != 0">
                <img :src="item.imageurls[0].url" class="news-img">
              </div>
            </div>
            <div v-else class="pics">
              <div class="title">{{item.title | ellipsis}}</div>
              <div class="flex">
                <img :src="item.imageurls[0].url">
                <img :src="item.imageurls[1].url">
                <img :src="item.imageurls[2].url">
              </div>
              <div class="desc flex">
                  <div>{{item.channelName}}</div>
                  <div>
                    <span>{{item.source}}</span>
                    <span>{{item.pubDate | dateFilter}}</span>
                  </div>
                </div>
            </div>
            
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getNews } from "../../utils/api";
import {uwStatisticAction} from "../../utils/util";
import moment from 'moment'
export default {
  data() {
    return {
      infoList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: '10',
      error: false,
    }
  },
  filters: {
    ellipsisTop (value) {
      if (!value) return ''
      if (value.length > 34) {
        return value.slice(0, 34) + '...'
      }
      return value
    },
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 23) {
        return value.slice(0, 23) + '...'
      }
      return value
    },
    dateFilter (value){
      return moment(value).format('YYYY-MM-DD')
    }
  },
  mounted() {
    uwStatisticAction('/toplistNewsList','toplist乡村新闻')
  },
  methods:{
    newsClick(id){
      this.$router.push({name:'newsInfo', query:{id:id}})
    },
    onLoad () {
      this.togetNews()
    },
    onRefresh () {
      this.infoList = [];
      this.isLoading = false;
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.pageIndex = 1;
      this.onLoad();
    },
    togetNews () {
      getNews({
        'pageIndex': this.pageIndex.toString(),
        'pageSize': this.pageSize
      }).then(res => {
        // 加载状态结束
        this.loading = false;
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.finished = !res.data.data.hasNextPage;
            if (this.pageIndex == 1) {
              this.infoList = res.data.data.list;
            } else {
              this.infoList = this.infoList.concat(res.data.data.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }
          } else {
            this.error = true;
          }
        } else {
          this.error = true;
        }
      }).catch(() => {
        this.error = true;
      })
    },
  }
}
</script>

<style lang="less" scoped>
.newsList{
  .info-list{
    padding: 0.3rem;
    .left{
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      min-height: 1.3rem;
      .title{
        font-size: 0.3rem;
        word-wrap: break-word;
        word-break: break-all;
        color: #000;
      }
      
    }
    .desc{
      font-size: 0.26rem;
      color: #999;
      justify-content: space-between;
      margin-top: 0.05rem;
      div{
        &:nth-child(2){
          margin-left: 0.5rem;
        }
        span{
          margin-right: 0.1rem;
        }
      }
    }
    .pics{
      img{
        flex:1;
        object-fit: cover;
        width: 1rem;
        height: 1.6rem;
        margin-right: 0.2rem;
        border-radius: 5px;
        &:nth-last-child(1){
          margin-right: 0;
        }
      }
    }
    
    .right{
      width: 2rem;
      flex: 0 0 2rem;
      margin-left: 0.1rem;
      .news-img{
        width: 2rem;
        height: 1.6rem;
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }
}
</style>
